// Rails
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("@rails/actiontext");

// Turbolinks
require("turbolinks").start();
Turbolinks.setProgressBarDelay(100);

// Bootstrap
require("bootstrap/dist/js/bootstrap");

// JQuery Validation
require("jquery-validation/dist/jquery.validate");
require("jquery-validation/dist/localization/messages_fr");

// Select2
require("select2/dist/js/select2.full");

// Trix
global.Trix = require("trix/dist/trix");

// Toastr
global.toastr = require("toastr");
require("../admin/config/toastr");

// Lazysizes
require("lazysizes/lazysizes");
require("lazysizes/plugins/unveilhooks/ls.unveilhooks");

// Bootbox
global.bootbox = require("../vendors/bootbox/bootbox.min");

// Cleave
require("cleave.js/dist/cleave");

// Shared files
require("../admin/main/shared/aside");

// Pages
require("../admin/auth/login");
require("../admin/main/developments/all");
require("../admin/main/units/all");
require("../admin/main/leads/all");
require("../admin/main/messages/all");
require("../admin/main/places/index");
require("../admin/main/labels/edit");
require("../admin/main/notifications/index");
