$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("messages") && $("body").hasClass("index")) {
    const $filtersForm = $("form#search-filters");
    const $searchType = $filtersForm.find('[name="search[type]"]');

    $searchType.on("change", (e) => {
      $(".results-wrapper").addClass("loading");
      const type = $('[name="search[type]"]:checked').val();

      const data = {
        type,
      };

      let url = messagesIndexURL;
      if (type !== "all") {
        const hash = $.param(data);
        url = url + "?" + hash;
      }
      Turbolinks.visit(url);
    });
  }
});
