$(document).on("turbolinks:load", () => {
  if (
    $("body").hasClass("auth") &&
    $("body").hasClass("sessions") &&
    $("body").hasClass("new")
  ) {
    const $loginForm = $("form.login-form");
    const $loginFormSubmitBtn = $loginForm.find('.btn[type="submit"]');

    const buildLoginForm = () => {
      $loginForm.validate({
        ignoreTitle: true,
        rules: {
          "admin[email]": { required: true, email: true },
          "admin[password]": { required: true },
        },
        messages: {
          "admin[email]": {
            required: "E-mail obligatoire",
            email: "E-mail non valide",
          },
          "admin[password]": {
            required: "Mot-de-passe obligatoire",
          },
        },
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form) => {
          $loginFormSubmitBtn.addClass("loading").attr("disabled", true);
          form.submit();
        },
        invalidHandler: (event, validator) => {
          $loginFormSubmitBtn.removeClass("loading").attr("disabled", false);
        },
      });
      $loginForm.removeClass("d-none");
    };

    buildLoginForm();
  }
});
