$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("notifications") && $("body").hasClass("index")) {
    const $filtersForm = $("form#search-filters");
    const $notifStatus = $(".nav-notifications .btn-status");
    const $allCnt = $("#all-notifs-count");
    const $unreadCnt = $("#unread-notifs-count");
    const $resultsBody = $(".results-body");

    const $searchType = $filtersForm.find('[name="search[type]"]');
    $searchType.on("change", (e) => {
      const type = $('[name="search[type]"]:checked').val();

      const data = {
        type,
      };

      let url = notificationsIndexURL;
      if (type !== "all") {
        const hash = $.param(data);
        url = url + "?" + hash;
      }

      $(".results-wrapper").addClass("loading");
      Turbolinks.visit(url);
    });

    const updateNotificationsBadge = (unreadCnt) => {
      if (unreadCnt > 0) {
        $notifStatus.show();
      } else {
        $notifStatus.hide();
      }
    };

    const cleanResultsBody = () => {
      const numNotifs = $(".notification-wrapper").length;
      if (numNotifs == 0) {
        $resultsBody.remove();
      }
    };

    $(".notification-wrapper .link a").on("click", (e) => {
      const $wrapper = $(e.currentTarget).closest(".notification-wrapper");
      $wrapper.removeClass("unread");
      const notificationId = $wrapper.data("notification-id");

      $.ajax({
        url: "/notifications/" + notificationId,
        method: "PUT",
        dataType: "json",
        success: (data, status, xhr) => {
          $allCnt.html("Toutes (" + data.all + ")");
          $unreadCnt.html("Non-lues (" + data.unread + ")");
          updateNotificationsBadge(data.unread);
          cleanResultsBody();
        },
        error: () => {
          toastr.error("Une erreur s'est produite");
        },
      });
    });

    $(".notification-wrapper .btn-delete").on("click", (e) => {
      e.preventDefault();
      const $wrapper = $(e.currentTarget).closest(".notification-wrapper");
      const notificationId = $wrapper.data("notification-id");

      $.ajax({
        url: "/notifications/" + notificationId,
        method: "DELETE",
        dataType: "json",
        success: (data, status, xhr) => {
          $wrapper.slideUp(200, () => {
            $wrapper.remove();
            $allCnt.html("Toutes (" + data.all + ")");
            $unreadCnt.html("Non-lues (" + data.unread + ")");
            updateNotificationsBadge(data.unread);
            cleanResultsBody();
          });
        },
        error: () => {
          toastr.error("Une erreur s'est produite");
        },
      });
    });
  }
});
