import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("units") && $("body").hasClass("show")) {
    lightGallery(document.getElementById("lg-plans"), {
      plugins: [lgZoom, lgThumbnail],
      mode: "lg-slide",
      speed: 500,
      loop: false,
      slideEndAnimation: false,
    });

    const openPlansGallery = () => {
      $("#lg-plans img:first").trigger("click");
    };

    $(".openPlansLG").on("click", (event) => {
      event.preventDefault();
      openPlansGallery();
    });
  }
});
