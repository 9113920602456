$(document).on("turbolinks:load", () => {
  const closedModeClass = "navbar-vertical-aside-closed-mode";
  const transitionClass = "navbar-vertical-aside-transition-on";
  let prevWitdth;

  const handleResizeEvent = (isInit) => {
    const ww = window.innerWidth;
    const $body = $("body");

    if (ww < 1200 && !$body.hasClass(closedModeClass)) {
      if (!isInit) {
        $body.addClass(transitionClass);
        setTimeout(() => {
          $body.removeClass(transitionClass);
        }, 200);
      }
      $body.addClass(closedModeClass);
    }

    if (prevWitdth < 1200 && ww >= 1200 && $body.hasClass(closedModeClass)) {
      $body.addClass(transitionClass);
      setTimeout(() => {
        $body.removeClass(transitionClass);
      }, 200);
      $body.removeClass(closedModeClass);
    }

    prevWitdth = ww;
  };

  const initializeAside = () => {
    const $aside = $(".js-navbar-vertical-aside");
    $aside.addClass("navbar-vertical-aside-initialized");
    const $asideOverlay = $(
      `<div class="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-mobile-overlay"></div>`
    );
    $asideOverlay.insertAfter($aside);
  };

  $(window).resize(function () {
    handleResizeEvent(false);
  });
  handleResizeEvent(true);

  initializeAside();

  $(".navbar-vertical-aside-toggle-short-align").on("click", (e) => {
    e.stopPropagation();
    $("body").addClass(transitionClass).addClass(closedModeClass);
    setTimeout(() => {
      $("body").removeClass("navbar-vertical-aside-transition-on");
    }, 200);
  });

  $(".navbar-vertical-aside-toggle-full-align").on("click", (e) => {
    e.stopPropagation();
    $("body").addClass(transitionClass).removeClass(closedModeClass);
    setTimeout(() => {
      $("body").removeClass(transitionClass);
    }, 200);
  });

  $(".js-navbar-vertical-aside-toggle-invoker").on("click", (e) => {
    $("body").addClass(transitionClass).addClass(closedModeClass);
    setTimeout(() => {
      $("body").removeClass("navbar-vertical-aside-transition-on");
    }, 200);
  });

  const showSubMenu = ($linkWrapper) => {
    const $subMenu = $linkWrapper.find(".js-navbar-vertical-aside-submenu");
    $linkWrapper.addClass("show");
    $subMenu.collapse("show");
  };

  const hideSubMenu = ($linkWrapper) => {
    const $subMenu = $linkWrapper.find(".js-navbar-vertical-aside-submenu");
    $linkWrapper.removeClass("show");
    $subMenu.collapse("hide");
  };

  const hideAllSubmenus = () => {
    $(".js-navbar-vertical-aside .navbar-vertical-aside-has-menu").each(
      (index, link) => {
        $(link).removeClass("show");
        $(link).find(".js-navbar-vertical-aside-submenu").collapse("hide");
      }
    );
  };

  $(".navbar-vertical-aside-has-menu .js-navbar-vertical-aside-menu-link").on(
    "click",
    (e) => {
      const $navLink = $(e.currentTarget);
      const $linkWrapper = $navLink.parent();
      const $subMenu = $linkWrapper.find(".js-navbar-vertical-aside-submenu");
      if ($linkWrapper.hasClass("show")) {
        hideSubMenu($linkWrapper);
      } else {
        showSubMenu($linkWrapper);
      }
    }
  );
});

$(document).on("turbolinks:before-render", () => {
  $(window).off("handleResizeEvent");
});
