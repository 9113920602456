$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("developments") && $("body").hasClass("index")) {
    const $filtersForm = $("form#search-filters");

    // Places
    const $searchPlacesWrapper = $filtersForm.find(".search-places-wrapper");
    const $searchPlacesSelect = $searchPlacesWrapper.find(
      "select#search-places-select"
    );

    const searchPlacesSelect2 = $searchPlacesSelect
      .select2({
        dropdownParent: $searchPlacesWrapper,
        minimumInputLength: 2,
        placeholder: "Ville, département, région...",
        allowClear: true,
        ajax: {
          url: searchPlacesURL,
          delay: 250,
          data: (params) => {
            const query = {
              text: params.term,
            };
            return query;
          },
          processResults: (data) => {
            const hits = data.hits;
            let res = [];

            $.each(hits, (key, values) => {
              let tmp = { text: key, children: [] };
              $.each(values, (index, val) => {
                tmp["children"].push({ id: val["pid"], text: val["name"] });
              });
              res.push(tmp);
            });

            return {
              results: res,
            };
          },
        },
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    searchPlacesSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    // Fiscality
    const $searchFiscalityWrapper = $filtersForm.find(
      ".search-fiscality-wrapper"
    );
    const $fiscalitySelect = $filtersForm.find(".select2#search_fiscality");
    const fiscalitySelect2 = $fiscalitySelect
      .select2({
        dropdownParent: $searchFiscalityWrapper,
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    fiscalitySelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    // Min Area
    let minAreaInput = new Cleave("#search_min_area", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: " ",
      numeralPositiveOnly: true,
    });

    // Max Price
    let maxPriceInput = new Cleave("#search_max_price", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: " ",
      numeralPositiveOnly: true,
    });

    // Sort
    const $sortOrderSelect = $(".select2#search_sort_order");

    if ($sortOrderSelect.length) {
      const sortOrderSelect2 = $sortOrderSelect.select2({
        minimumResultsForSearch: Infinity,
      });

      sortOrderSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $sortOrderSelect.on("change", function (e) {
        e.preventDefault();
        let filters = buildFilters();
        const order = $sortOrderSelect.val();

        if (order != "created_desc") {
          filters["sort_order"] = $sortOrderSelect.val();
        }

        let url = developmentsIndexURL;
        const ct = Object.keys(filters).length;
        if (ct > 0) {
          const hash = $.param(filters);
          url = url + "?" + hash;
        }

        Turbolinks.visit(url);
      });

      $(".results-header__sort").addClass("d-flex").removeClass("d-none");
    }

    const buildFilters = () => {
      const filters = {};

      const placePID = $searchPlacesSelect.val();
      if (placePID) {
        filters["place_pid"] = placePID;
      }

      const fiscality = $fiscalitySelect.val();
      if (fiscality) {
        filters["fiscality"] = fiscality;
      }

      const numRooms = [];
      $filtersForm.find('[name="num_rooms[]"]:checked').each(function () {
        numRooms.push($(this).val());
      });
      if (numRooms.length) {
        filters["num_rooms"] = numRooms;
      }

      const minArea = minAreaInput.getRawValue();
      if (minArea) {
        filters["min_area"] = minArea;
      }

      const maxPrice = maxPriceInput.getRawValue();
      if (maxPrice) {
        filters["max_price"] = maxPrice;
      }

      return filters;
    };

    $filtersForm.on("submit", (e) => {
      e.preventDefault();

      $(".results-wrapper").addClass("loading");
      const filters = buildFilters();

      let url = developmentsIndexURL;
      if (!$.isEmptyObject(filters)) {
        let queryParams = $.param(filters);
        url = url + "?" + queryParams;
      }
      Turbolinks.visit(url);
    });
  }
});
