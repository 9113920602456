$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("places") && $("body").hasClass("index")) {
    const $searchPlacesWrapper = $(".search-places-wrapper");
    const $searchPlacesSelect = $searchPlacesWrapper.find(
      "select#search-places-select"
    );

    if ($searchPlacesSelect.length) {
      const placesSelect2 = $searchPlacesSelect.select2({
        minimumInputLength: 2,
        placeholder: "Département, région...",
        dropdownParent: $searchPlacesWrapper,
        ajax: {
          url: searchPlacesURL,
          delay: 250,
          data: (params) => {
            const query = {
              text: params.term,
            };
            return query;
          },
          processResults: (data) => {
            const hits = data.hits;
            let res = [];

            $.each(hits, (key, values) => {
              let tmp = { text: key, children: [] };
              $.each(values, (index, val) => {
                tmp["children"].push({ id: val["pid"], text: val["name"] });
              });
              res.push(tmp);
            });

            return {
              results: res,
            };
          },
        },
      });

      placesSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $searchPlacesSelect.on("change", (event) => {
        const pid = $(event.currentTarget).val();
        window.location.href = "/geodb/map/" + pid;
      });
    }

    // Show form
    $searchPlacesWrapper.removeClass("d-none");
  }
});
