import { prettyFileSize } from "../../../lib/file";
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Sortable from "sortablejs/Sortable";

$(document).on("turbolinks:load", () => {
  if (
    $("body").hasClass("units") &&
    ($("body").hasClass("new") || $("body").hasClass("edit"))
  ) {
    const $form = $("form#edit-unit");
    const $submitBtn = $form.find("[type=submit]");

    $form.validate({
      ignoreTitle: true,
      rules: {},
      messages: {},
      errorElement: "label",
      errorPlacement: (error, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).addClass("has-error");
        $(fg).append(error);
      },
      success: (label, element) => {
        var fg = $(element).closest(".form-group");
        $(fg).removeClass("has-error");
      },
      submitHandler: (form) => {
        $submitBtn.addClass("loading").attr("disabled", true);
        form.submit();
      },
      invalidHandler: (event, validator) => {
        $submitBtn.removeClass("loading").attr("disabled", false);
      },
    });

    // Prop Type
    var $propTypeSelect = $form.find(".select2[name='unit[prop_type]']");
    const propTypeSelect2 = $propTypeSelect
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    propTypeSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    $propTypeSelect.on("change", (event) => {
      const $this = $(event.currentTarget);
      $this.closest(".form-group").addClass("has-error");
      $this.valid();
    });

    // Floor
    let floor = new Cleave("#unit_floor", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Living Area
    let livingArea = new Cleave("#unit_living_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num rooms
    let numRooms = new Cleave("#unit_num_rooms", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num bedrooms
    let numBedrooms = new Cleave("#unit_num_bedrooms", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num parkings
    let numParkings = new Cleave("#unit_num_parkings", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Num parkings
    let numCellars = new Cleave("#unit_num_cellars", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Garden Area
    let gardenArea = new Cleave("#unit_garden_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Terrace Area
    let terraceArea = new Cleave("#unit_terrace_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Balcony Area
    let balconyArea = new Cleave("#unit_balcony_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    // Loggia Area
    let loggiaArea = new Cleave("#unit_loggia_area", {
      numeral: true,
      numeralDecimalMark: ",",
      delimiter: "",
      numeralPositiveOnly: true,
    });

    let parkingsPriceFullVAT = new Cleave("#unit_parkings_price_full_vat", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: " ",
      numeralPositiveOnly: true,
    });

    let cellarsPriceFullVAT = new Cleave("#unit_cellars_price_full_vat", {
      numeral: true,
      numeralDecimalMark: "",
      delimiter: " ",
      numeralPositiveOnly: true,
    });

    // Status
    const $statusSelect = $form.find(".select2#unit_status");
    const statusSelect2 = $statusSelect
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: "Choisir",
        allowClear: true,
      })
      .on("select2:unselecting", (event) => {
        $(event.currentTarget).data("unselecting", true);
      })
      .on("select2:opening", (event) => {
        const $this = $(event.currentTarget);
        if ($this.data("unselecting")) {
          $this.removeData("unselecting");
          event.preventDefault();
        }
      });

    statusSelect2
      .data("select2")
      .$container.find(".select2-selection")
      .removeClass("select2-selection--single")
      .addClass("custom-select");

    $statusSelect.on("change", (event) => {
      const $this = $(event.currentTarget);
      $this.closest(".form-group").addClass("has-error");
      $this.valid();
    });

    if (fiscality == "fnone" || fiscality == "fpinel") {
      let priceFullVAT = new Cleave("#unit_price_full_vat", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });
    }

    if (fiscality == "fpinel") {
      // 1 - Weighted Area
      let weightedArea = new Cleave("#unit_weighted_area", {
        numeral: true,
        numeralDecimalMark: ",",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      const $weightedAreaModal = $(".modal#weighted-area-modal");
      let weightedAreaTmpValue = 0;

      $weightedAreaModal.find(".btn.applyValue").on("click", (e) => {
        weightedArea.setRawValue(weightedAreaTmpValue);
        $weightedAreaModal.modal("hide");
      });

      $(".evalWeightedArea").on("click", (e) => {
        e.preventDefault();

        if (livingArea.getRawValue() === "") {
          bootbox.alert({
            className: "",
            centerVertical: true,
            backdrop: true,
            message: `<div class="py-2">Veuillez renseigner la surface habitable !</div>`,
            closeButton: false,
            buttons: {
              ok: {
                label: "OK",
                className: "btn-secondary px-5",
              },
            },
          });
          return;
        }

        const data = {
          living_area: parseFloat(livingArea.getRawValue() || 0),
          annexes_area:
            parseFloat(terraceArea.getRawValue() || 0) +
            parseFloat(balconyArea.getRawValue() || 0) +
            parseFloat(loggiaArea.getRawValue() || 0),
        };

        $.ajax({
          url: evalWeightedAreaURL,
          type: "POST",
          data: { data },
          dataType: "json",
          success: (data, status, xhr) => {
            $weightedAreaModal
              .find("td.living-area")
              .html(data.living_area + "m<sup>2</sup>");
            $weightedAreaModal
              .find("td.annexes-area")
              .html(data.annexes_area + "m<sup>2</sup>");
            $weightedAreaModal
              .find("td.weighted-area")
              .html(data.weighted_area + "m<sup>2</sup>");
            weightedAreaTmpValue = parseFloat(data.weighted_area);
            $weightedAreaModal.modal("show");
          },
          error: (res) => {
            console.log(res.responseJSON.errors);
          },
        });
      });

      // 2 - Pinel Rent
      let pinelRent = new Cleave("#unit_pinel_rent", {
        numeral: true,
        numeralDecimalMark: ",",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      const $pinelRentModal = $(".modal#pinel-rent-modal");
      let pinelRentTmpValue = 0;

      $pinelRentModal.find(".btn.applyValue").on("click", (e) => {
        pinelRent.setRawValue(pinelRentTmpValue);
        $pinelRentModal.modal("hide");
      });

      $(".evalPinelRent").on("click", (e) => {
        e.preventDefault();

        if (weightedArea.getRawValue() === "") {
          bootbox.alert({
            className: "",
            centerVertical: true,
            backdrop: true,
            message: `<div class="py-2">Veuillez renseigner la surface pondérée !</div>`,
            closeButton: false,
            buttons: {
              ok: {
                label: "OK",
                className: "btn-secondary px-5",
              },
            },
          });
          return;
        }

        const data = {
          weighted_area: parseFloat(weightedArea.getRawValue() || 0),
          pinel_zone: pinelZone,
        };

        $.ajax({
          url: evalPinelRentURL,
          type: "POST",
          data: { data },
          dataType: "json",
          success: (data, status, xhr) => {
            $pinelRentModal
              .find("td.weighted-area")
              .html(data.weighted_area + "m<sup>2</sup>");
            $pinelRentModal.find("td.pinel-zone").html(data.pinel_zone);
            $pinelRentModal
              .find("td.pinel-ceiling")
              .html(data.pinel_ceiling + "€/m<sup>2</sup>");
            $pinelRentModal
              .find("td.pinel-coefficient")
              .html(data.pinel_coefficient);
            $pinelRentModal.find("td.pinel-rent").html(data.pinel_rent + "€");

            pinelRentTmpValue = parseFloat(data.pinel_rent);
            $pinelRentModal.modal("show");
          },
          error: (res) => {
            console.log(res.responseJSON.errors);
          },
        });
      });
    }

    if (fiscality == "flmnp") {
      let lmnpPriceHT = new Cleave("#unit_lmnp_price_ht", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });

      let lmnpImmoHT = new Cleave("#unit_lmnp_immo_price_ht", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });

      let lmnpFurnitureHT = new Cleave("#unit_lmnp_furniture_price_ht", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });

      let lmnpRentHT = new Cleave("#unit_lmnp_rent_ht", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });
    }

    if (fiscality == "fnue") {
      let nuePrice = new Cleave("#unit_nue_price", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });
    }

    // Plans
    const $plansGallery = $("#unit-plans");

    const buildIndexes = () => {
      $("#unit-plans .plan-wrapper").each((index, elem) => {
        const newIndex = index + 1;
        $(elem)
          .find(".keyInput")
          .attr("name", "plans[" + newIndex + "][key]");
      });
    };

    const buildEvents = () => {
      $("#unit-plans .removePlan").on("click", (event) => {
        if (confirm("Supprimer ce plan?")) {
          $(event.currentTarget).closest(".plan-wrapper").remove();
          buildIndexes();
        }
      });
    };

    // Sort
    let sortable = new Sortable(document.getElementById("unit-plans"), {
      animation: 150,
      onUpdate: (event) => {
        buildIndexes();
      },
    });

    const addFile = (file) => {
      const index = $plansGallery.find(".plan-wrapper").length + 1;

      const tpl = `
        <div class="plan-wrapper col-12 col-md-6 col-lg-4 plan-sort">
          <div class="card">
            <div class="card-cover">
              <img src="${cloudFrontURL}/${file.response.key}" />
            </div>
            <div class="card-body">
              <table class="details">
                <tbody>
                  <tr>
                    <td>Type</td>
                    <td>${file.response.content_type}</td>
                  </tr>
                  <tr>
                    <td>Taille</td>
                    <td>${prettyFileSize(file.response.byte_size)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <a href="javascript:;" class="text-danger removePlan">Supprimer</a>
            </div>
          </div>
          <input type="hidden" name="plans[${index}][key]" class="keyInput" value="${
        file.response.key
      }">
        </div>
      `;

      $plansGallery.append(tpl);
    };

    const setUpUppy = () => {
      let trigger = document.querySelector("[data-behavior='uppy-trigger']");
      let directUploadUrl = $("meta[name='direct-upload-url']").attr("content");

      trigger.addEventListener("click", (e) => {
        e.preventDefault();
      });

      let uppy = Uppy({
        autoProceed: true,
        allowMultipleUploads: true,
        logger: Uppy.debugLogger,
      });

      uppy.use(ActiveStorageUpload, {
        directUploadUrl: directUploadUrl,
      });

      uppy.use(Dashboard, {
        trigger: trigger,
        closeAfterFinish: false,
      });

      uppy.on("complete", (result) => {
        result.successful.forEach((file) => {
          console.log(file);
          addFile(file);
          buildIndexes();
          buildEvents();
        });
      });
    };

    buildIndexes();
    buildEvents();
    setUpUppy();
  }
});
