import List from "list.js";

$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("units") && $("body").hasClass("index")) {
    const $filtersForm = $("form#search-filters");
    let minAreaInput;
    let maxPriceInput;

    let $unitsTable = $("table.unitsTable");
    let unitsList = null;

    const buildList = () => {
      var options = {};
      if (fiscality === "fnone" || fiscality === "fpinel") {
        options = {
          valueNames: ["id", "rooms", "floor", "area", "price", "status"],
        };
      } else if (fiscality == "flmnp") {
        options = {
          valueNames: [
            "id",
            "rooms",
            "floor",
            "area",
            "price",
            "rent",
            "return",
            "status",
          ],
        };
      } else if (fiscality == "fnue") {
        options = {
          valueNames: ["id", "rooms", "floor", "area", "price", "status"],
        };
      }

      unitsList = new List("units", options);
    };

    const updateUnitStatus = (status, uid) => {
      const data = {
        uid,
        status,
      };

      $.ajax({
        url: updateUnitStatusURL,
        type: "PUT",
        data: { data },
        dataType: "json",
        success: (data, status, xhr) => {
          const newStatus = data.status;
          if (newStatus === "available") {
            $unitsTable
              .find(`tbody tr#${uid}`)
              .removeClass()
              .addClass("available");
            $unitsTable.find(`tbody tr#${uid} td.statusCell`).html(`
              <span class="badge badge-available">DISPONIBLE</span>
              <span class="status d-none">available</span>
            `);
            $unitsTable
              .find(`tbody tr#${uid} td.actionsCell .statusAvailable`)
              .addClass("d-none");
            $unitsTable
              .find(`tbody tr#${uid} td.actionsCell .statusSold`)
              .removeClass("d-none");
          } else if (newStatus === "sold") {
            $unitsTable.find(`tbody tr#${uid}`).removeClass().addClass("sold");
            $unitsTable.find(`tbody tr#${uid} td.statusCell`).html(`
              <span class="badge badge-sold">RÉSERVÉ</span>
              <span class="status d-none">sold</span>
            `);
            $unitsTable
              .find(`tbody tr#${uid} td.actionsCell .statusAvailable`)
              .removeClass("d-none");
            $unitsTable
              .find(`tbody tr#${uid} td.actionsCell .statusSold`)
              .addClass("d-none");
          }
          unitsList.reIndex();
        },
        error: (res) => {
          console.log(res);
        },
      });
    };

    const buildEvents = () => {
      $unitsTable.find("a.statusSold").on("click", (e) => {
        const { uid } = e.currentTarget.dataset;
        updateUnitStatus("sold", uid);
      });

      $unitsTable.find("a.statusAvailable").on("click", (e) => {
        const { uid } = e.currentTarget.dataset;
        updateUnitStatus("available", uid);
      });
    };

    const buildFilters = () => {
      // Min Area
      minAreaInput = new Cleave("#search_min_area", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });

      // Max Price
      maxPriceInput = new Cleave("#search_max_price", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });
    };

    if (parseInt(numUnits) > 0) {
      buildFilters();
      buildList();
      buildEvents();
    }

    const evalFilters = () => {
      const filters = {};

      const propTypes = [];
      $filtersForm.find('[name="prop_types[]"]:checked').each(function () {
        propTypes.push($(this).val());
      });
      if (propTypes.length) {
        filters["prop_types"] = propTypes;
      }

      const numRooms = [];
      $filtersForm.find('[name="num_rooms[]"]:checked').each(function () {
        numRooms.push($(this).val());
      });
      if (numRooms.length) {
        filters["num_rooms"] = numRooms;
      }

      const minArea = minAreaInput.getRawValue();
      if (minArea) {
        filters["min_area"] = minArea;
      }

      const maxPrice = maxPriceInput.getRawValue();
      if (maxPrice) {
        filters["max_price"] = maxPrice;
      }

      const annexes = [];
      $filtersForm.find('[name="annexes[]"]:checked').each(function () {
        annexes.push($(this).val());
      });
      if (annexes.length) {
        filters["annexes"] = annexes;
      }

      return filters;
    };

    $filtersForm.on("submit", (e) => {
      e.preventDefault();

      $(".results-wrapper").addClass("loading");
      const filters = evalFilters();

      let url = unitsIndexURL;
      if (!$.isEmptyObject(filters)) {
        let queryParams = $.param(filters);
        url = url + "?" + queryParams;
      }
      Turbolinks.visit(url);
    });
  }
});
