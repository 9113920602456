import mapboxgl from "!mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

$(document).on("turbolinks:load", () => {
  if ($("body").hasClass("developments") && $("body").hasClass("edit-infos")) {
    const $form = $("form#edit-infos");
    let mapMarker = null;

    const initForm = () => {
      const $submitBtn = $form.find("[type=submit]");

      $form.validate({
        ignoreTitle: true,
        rules: {},
        messages: {},
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form) => {
          $submitBtn.addClass("loading").attr("disabled", true);
          form.submit();
        },
        invalidHandler: (event, validator) => {
          $submitBtn.removeClass("loading").attr("disabled", false);
        },
      });

      // Stage
      var $stageSelect = $form.find(".select2[name='development[stage]']");
      const stageSelect2 = $stageSelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      stageSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $stageSelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
      });

      // Latitude, Longitude
      $form.find("input#development_latitude").on("change", () => {
        updateMap();
      });

      $form.find("input#development_longitude").on("change", () => {
        updateMap();
      });

      // Pinel Zone
      const $pinelZoneSelect = $form.find(
        ".select2[name='development[pinel_zone]']"
      );

      if ($pinelZoneSelect.length) {
        const pinelZoneSelect2 = $pinelZoneSelect
          .select2({
            minimumResultsForSearch: Infinity,
            placeholder: "Choisir",
            allowClear: true,
          })
          .on("select2:unselecting", (event) => {
            $(event.currentTarget).data("unselecting", true);
          })
          .on("select2:opening", (event) => {
            const $this = $(event.currentTarget);
            if ($this.data("unselecting")) {
              $this.removeData("unselecting");
              event.preventDefault();
            }
          });

        pinelZoneSelect2
          .data("select2")
          .$container.find(".select2-selection")
          .removeClass("select2-selection--single")
          .addClass("custom-select");

        $pinelZoneSelect.on("change", (event) => {
          const $this = $(event.currentTarget);
          $this.closest(".form-group").addClass("has-error");
          $this.valid();
        });
      }

      // LMNP Residence Type
      const $lmnpResidenceTypeSelect = $form.find(
        ".select2[name='development[lmnp_residence_type]']"
      );

      if ($lmnpResidenceTypeSelect.length) {
        const lmnpResidenceTypeSelect2 = $lmnpResidenceTypeSelect
          .select2({
            minimumResultsForSearch: Infinity,
            placeholder: "Choisir",
            allowClear: true,
          })
          .on("select2:unselecting", (event) => {
            $(event.currentTarget).data("unselecting", true);
          })
          .on("select2:opening", (event) => {
            const $this = $(event.currentTarget);
            if ($this.data("unselecting")) {
              $this.removeData("unselecting");
              event.preventDefault();
            }
          });

        lmnpResidenceTypeSelect2
          .data("select2")
          .$container.find(".select2-selection")
          .removeClass("select2-selection--single")
          .addClass("custom-select");

        $lmnpResidenceTypeSelect.on("change", (event) => {
          const $this = $(event.currentTarget);
          $this.closest(".form-group").addClass("has-error");
          $this.valid();
        });
      }
    };

    const initMap = () => {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiYWxkaW1tbyIsImEiOiJjamh2cTVxcm4wd3Q2M3Jxa3N0dDMxbThkIn0.rrCseKZtVNHzJLRUBNXy3w";

      window.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [2.6187, 47.8249],
        zoom: 4,
        interactive: true,
        scrollZoom: false,
      });

      var nav = new mapboxgl.NavigationControl();
      map.addControl(nav, "top-right");

      map.on("load", (e) => {
        map.resize();
      });
    };

    const initGeocoder = () => {
      var geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        types: "address",
      });

      geocoder.addTo("#mapbox-geocoder-wrapper");

      geocoder.on("result", (e) => {
        console.log(e.result);
        const result = e.result;

        $form.find("input#development_address").val(result["place_name"]);

        for (var k in result.context) {
          const { id, text } = result.context[k];
          const tg = id.split(".")[0];

          switch (tg) {
            case "postcode":
              $form.find("input#development_zipcode").val(text);
              break;

            case "place":
              $form.find("input#development_city").val(text);

            default:
              break;
          }

          const lat = result.geometry.coordinates[1];
          const lng = result.geometry.coordinates[0];

          $form.find("input#development_latitude").val(lat);
          $form.find("input#development_longitude").val(lng);

          $form.find("input#development_address").valid();
          $form.find("input#development_zipcode").valid();
          $form.find("input#development_city").valid();
          $form.find("input#development_latitude").valid();
          $form.find("input#development_longitude").valid();

          updateMap();
        }
      });
    };

    const updateMap = () => {
      const lat = $form.find("input#development_latitude").val();
      const lng = $form.find("input#development_longitude").val();

      if (mapMarker) {
        mapMarker.remove();
      }

      var ll = new mapboxgl.LngLat(lng, lat);
      mapMarker = new mapboxgl.Marker().setLngLat(ll).addTo(map);

      map.flyTo({ center: [lng, lat], zoom: 15 });
    };

    initForm();
    initMap();
    initGeocoder();
    updateMap();
  }
});

$(document).on("turbolinks:before-render", () => {
  if ($("body").hasClass("developments") && $("body").hasClass("edit-infos")) {
    if (window.map) {
      delete window.map;
    }
  }
});
