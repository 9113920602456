import { prettyFileSize } from "../../../../lib/file";
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Sortable from "sortablejs/Sortable";

$(document).on("turbolinks:load", () => {
  if (
    $("body").hasClass("developments") &&
    $("body").hasClass("edit-documents")
  ) {
    const $form = $("form#edit-documents");
    const $documentsList = $("#development-documents");
    const $submitBtn = $form.find("[type=submit]");

    $submitBtn.on("click", (event) => {
      $(event.currentTarget).addClass("loading");
    });

    const buildIndexes = () => {
      $("#development-documents .document-wrapper").each((index, elem) => {
        const newIndex = index + 1;
        $(elem)
          .find(".keyInput")
          .attr("name", "documents[" + newIndex + "][key]");
        $(elem)
          .find(".publicNameInput")
          .attr("name", "documents[" + newIndex + "][public_name]");
      });
    };

    const buildEvents = () => {
      $("#development-documents .removeDoc").off("click");
      $("#development-documents .removeDoc").on("click", (event) => {
        if (confirm("Supprimer ce document?")) {
          $(event.currentTarget).closest(".document-wrapper").remove();
          buildIndexes();
        }
      });

      $("#development-documents .editDoc").off("click");
      $("#development-documents .editDoc").on("click", (event) => {
        bootbox.prompt({
          className: "bootbox-modal",
          title: "Nouveau nom du fichier",
          centerVertical: true,
          buttons: {
            cancel: {
              label: "Annuler",
              className: "btn-secondary",
            },
            confirm: {
              label: "Sauvegarder",
              className: "btn-primary",
            },
          },
          callback: function (result) {
            if (result) {
              var $document = $(event.currentTarget).closest(
                ".document-wrapper"
              );
              $document.find("h5.publicName").html(result);
              $document.find(".publicNameInput").val(result);
            }
          },
        });
      });
    };

    const addUploadedFile = (file) => {
      const index = $documentsList.find(".document-wrapper").length + 1;
      const fileURL = `${cloudFrontURL}/${file.response.key}`;

      const tpl = `
        <div class="document-wrapper col-12 col-md-6 col-lg-4 document-sort">
          <div class="card">
            <div class="card-body">
              <h5 class="publicName">${file.response.filename}</h5>
              <table class="details">
                <tbody>
                  <tr>
                    <td>Lien</td>
                    <td><a href="${fileURL}" class="link-body-color-underline" target="_blank">Voir le document</a></td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>${file.response.content_type}</td>
                  </tr>
                  <tr>
                    <td>Taille</td>
                    <td>${prettyFileSize(file.response.byte_size)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <a href="javascript:;" class="text-primary editDoc me-3">Modifier le nom</a>
              <a href="javascript:;" class="text-danger removeDoc">Supprimer</a>
            </div>
          </div>
          <input type="hidden" name="documents[${index}][key]" 
                 class="keyInput" value="${file.response.key}">
          <input type="hidden" name="documents[${index}][public_name]" 
                 class="publicNameInput" value="${file.response.filename}">
        </div>
      `;

      $documentsList.append(tpl);
    };

    // Sort
    var sortable = new Sortable(
      document.getElementById("development-documents"),
      {
        animation: 150,
        onUpdate: (event) => {
          buildIndexes();
        },
      }
    );

    const setUpUppy = () => {
      let trigger = document.querySelector("[data-behavior='uppy-trigger']");
      let directUploadUrl = $("meta[name='direct-upload-url']").attr("content");

      trigger.addEventListener("click", (e) => {
        e.preventDefault();
      });

      let uppy = Uppy({
        autoProceed: true,
        allowMultipleUploads: true,
        logger: Uppy.debugLogger,
      });

      uppy.use(ActiveStorageUpload, {
        directUploadUrl: directUploadUrl,
      });

      uppy.use(Dashboard, {
        trigger: trigger,
        closeAfterFinish: false,
      });

      uppy.on("complete", (result) => {
        result.successful.forEach((file) => {
          console.log(file);
          addUploadedFile(file);
          buildIndexes();
          buildEvents();
        });
      });
    };

    buildIndexes();
    buildEvents();
    setUpUppy();
  }
});
